/*!
---
name: .color-box
category: Box/Color
tag:
 - component
 - border
compile: true
---

A box to control the background color. It makes a variant foreach color definion in the file `color-box.var.styl`

```jade
div.color-box.-white(style="width:100px;height:100px")
```

```jade
div.color-box.-action(style="width:100px;height:100px")
```
*/
.color-box {
    
    conf = theme.components.color-box.colors;

    for key, value in conf{
        &.-{key}{
            background-color: value;    
        }
    }
}