.drag-drop {

    .drag-container {
        background-color: grey-color;
        

    }
    
    .drag-node {
        border: 1px dashed #444;
        cursor: pointer;
        max-width: 60px;

    }

    .drop-node {
        width: 100%;
    }
    .drop-target {
        border: 1px dashed #444;
        min-height: 45px;
        //display: inline-block;
        width: 100%;
        //vertical-align: top;

        >.drag-node {
            display:inline-block;
        }
    }
}