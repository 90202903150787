/*!
---
name: .transform-box
category: Box/Transform
tag:
 - component
 - tranform
 - helper
compile: true
---

A box to control common transformations

## Rotate
```jade
div.transform-box.-rot90 Some demo content
div.transform-box.-rot180 Some demo content
div.transform-box.-rot270 Some demo content
```

## Flip
```jade
div.transform-box.-flipX Some demo content
div.transform-box.-flipY Some demo content
```
*/
.transform-box {

	conf = theme.components.transform-box;

	for name, value in conf {
		&.-{name} {
			{value}
		}
	}

}