/*!
---
name: .fixed-layout
category: Layout/Fixed
tag:
 - container
---

Fixed container
You can position the container with the different variants `.-top`, `.-bottom`, `.-left`, `.-right`.
To span full width / height use `.-left` & `.-right` / `.-top` & `.-bottom`

Fixed top and full width

```jade
div.fixed-layout.-top.-left.-right.aigis-debug top left fullwidth
    
```

Fixed right and full height

```jade
div.fixed-layout.-top.-bottom.-right.aigis-debug top right fullheight
    
```

*/

.fixed-layout {
	
    position:fixed;

    &.-top{
        top: 0;
    }

    &.-bottom{
        bottom: 0;
    }

    &.-left{
        left: 0;
    }

    &.-right{
        right: 0;
    }
}