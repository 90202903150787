/*!
---
name: .slide-overlay
category: Overlay/Slide
tag:
 - rollover
 - slide
 - overlay
---

Rollover component - content rollover rolls in - default from bottom

Trigger with mouserover by adding `.-hover` variant

Trigger with js by adding `.-show` variant

```jade
div.slide-overlay.-hover
    div Some main content
    div.rollover Rollover content
```

Rollover component variant -left
```jade
div.slide-overlay.-left.-hover
    div Some main content
    div.rollover Rollover content
```

Rollover component variant -right
```jade
div.slide-overlay.-right.-hover
    div Some main content
    div.rollover Rollover content
```

Rollover component variant -top
```jade
div.slide-overlay.-top.-hover
    div Some main content
    div.rollover Rollover content
```

Rollover component variant -top with js trigger
```jade
div.slide-overlay.-top.-show
    div Some main content
    div.rollover Rollover content
```
*/
.slide-overlay {
	
    position: relative;
    overflow: hidden;

    conf = theme.components.slide-overlay;

    > .rollover {
        position: absolute;
        bottom: 0;
        transform: translate( 0, 100% );
        transition: transform conf.trans-duration ease;
    }

    &.-left {
        > .rollover{
            transform: translate( -100%, 0 );
            left: 0;
        }
    }

    &.-right {
        > .rollover{
            transform: translate( 100%, 0 );
            right: 0;
        }
    }

    &.-top {
        > .rollover{
            transform: translate( 0, -100% );
            top: 0;
        }
    }

    &.-show > .rollover {
        transform: translate( 0, 0 );
    }

    
    &.-hover:hover > .rollover {
        transform: translate( 0, 0 );
    }
}