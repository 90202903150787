/*!
---
name: .text-style
category: Text
tag:
 - component
 - text
compile: true
---

A text styling component for different text styles

## Default heading

```jade
div.text-style Default heading
```

## H1
```jade
div.text-style.-h1 The h1 header

```

## H2
```jade
div.text-style.-h2 The h2 header
```

## H3
```jade
div.text-style.-h3 The h3 header
```

## Bold
```jade
div.text-style.-bold Bold text
```

## Align center
```jade
div.text-style.-center Centered text
```

## Align right
```jade
div.text-style.-right Right aligned text
```

*/



.text-style {

	for name, value in theme.components.text-style {
	
		
		&.-{name} {
			{value}
		}
        
	}
}


