
.feedback-panel {
	line-height: 28px;

	padding: rem(40px) rem(30px) rem(20px) rem(30px);
	
	margin: rem(-24px) 0 0 0;

	&.-correct {
		border: 1px solid #4bd2a1;
		background-color: #E0F5EE;
		color: #4bd2a1;
	}
	
	&.-wrong {
		border: 1px solid #d24b4b;
		background-color: #F5E2E2;
		color: #d24b4b;
		
	}

}