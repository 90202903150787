/*
---
name: Colors
category: Shared/Variables
template: colors
tag:
 - variables
 - colors
colors:
 - variable: "text-color"
   description: "The default text color on the body"
   value: "#3F3F40"
 - variable: "text-inverted-color"
   description: "The default inverted text color on the body"
   value: "#FFF"
 - variable: "action-color"
   description: "The main action color used for links and buttons"
   value: "#EC6449"
 - variable: "body-color"
   description: "The main background color"
   value: "#FFF"
 - variable: "enabled-color"
   description: "Signalcolor for enabled/ok/success/true - search for a better name"
   value: "#7CAE7A"
 - variable: "disabled-color"
   description: "Signalcolor for disabled/notok/error/false - search for a better name"
   value: "#EC6449"
 - variable: "outline-navigation-color"
   description: "Color for the elements in the outline navigation"
   value: "lighten( #3F3F40, 60 )"
---

The color variables that are used throughout the library. 
*/
/*
---
name: Global settings
category: Shared/Variables
template: variables
tag:
 - variables
variables:
 - name: "text-line-height"
   description: "The line height of the body"
   default: "1.5rem"
 - name: "font-family"
   description: "The font-family of the body"
   default: "'Helvetica', sans-serif"
 - name: "text-rendering"
   description: "The text-rendering of the body"
   default: "optimizeLegibility"
 - name: "transition-duration"
   description: "The duration of the slide transitions"
   default: "0.4s"
 - name: "border-radius"
   description: "The default radius for borders"
   default: "2px"
---

These variables setups some global text formatting and styling.
*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: inherit;
  vertical-align: baseline;
}
:root {
  font-size: 16px;
}
html {
  font-size: 100%;
}
body {
  line-height: 1.5rem;
  color: #2d2d2d;
  font-family: 'Open Sans' , sans-serif;
  text-rendering: optimizeLegibility;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
select {
  vertical-align: middle;
}
textarea {
  width: 100%;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
a {
  text-decoration: none;
  color: #73cfa4;
}
img {
  display: block;
}
ul,
ol {
  margin-left: 1.25rem;
}
/*
---
name: .border-box
category: Box/Border
tag:
 - component
 - border
compile: true
---

A box to control borders on all four sides.

```jade
div.border-box.-left Some demo content
```

```jade
div.border-box.-all Some demo content
```
*/
.border-box.-top {
  border-top-style: solid;
  border-width: 1px;
  border-color: #e0e0e0;
}
.border-box.-right {
  border-right-style: solid;
  border-width: 1px;
  border-color: #e0e0e0;
}
.border-box.-bottom {
  border-bottom-style: solid;
  border-width: 1px;
  border-color: #e0e0e0;
}
.border-box.-left {
  border-left-style: solid;
  border-width: 1px;
  border-color: #e0e0e0;
}
.border-box.-all {
  border-style: solid;
  border-width: 1px;
  border-color: #e0e0e0;
}
.border-box.-round {
  border-radius: 50%;
}
/*
---
name: .color-box
category: Box/Color
tag:
 - component
 - border
compile: true
---

A box to control the background color. It makes a variant foreach color definion in the file `color-box.var.styl`

```jade
div.color-box.-white(style="width:100px;height:100px")
```

```jade
div.color-box.-action(style="width:100px;height:100px")
```
*/
.color-box.-body {
  background-color: #fff;
}
.color-box.-action {
  background-color: #3031fe;
}
.color-box.-white {
  background-color: #fff;
}
.color-box.-black {
  background-color: #000;
}
/*
---
name: .margin-box
category: Box/Margin
tag:
 - component
 - layout
compile: true
---

A component to add margins

## Applying margins

The easiest use case is to use simple `.margin-box .-all` for a margin on top, right, bottom and left with default size or
`.-top`, `.-right`, `.-bottom` and `.-left` for specified sides.

```jade
div.margin-box.-all
	div.aigis-debug Default margin applied on top, right, bottom and left
```
```jade
div.margin-box.-bottom
	div.aigis-debug Default margin applied on bottom
```


## Variants

The variants for the margins are `.-top-{size}`, `.-right-{size}`, `.-bottom-{size}` and `.left-{size}` together with sizes small, medium or large.
All sizes are defined in the variable theme.components.margin-box. Values are `small`, `medium`, `large` and `auto`

```jade
div.margin-box.-left-small
    div.aigis-debug Small margin applied on left
```
```jade
div.margin-box.-right-small
    div.aigis-debug Small margin applied on right
```
```jade
div.margin-box.-top-small
    div.aigis-debug Small margin applied on top
```
```jade
div.margin-box.-bottom-small
    div.aigis-debug Small margin applied on bottom
```

There are also shortcuts for vertical `.-v-{size}` and horizontal `.-h-{size}` together with sizes small, medium and large

```jade
div.margin-box.-v-medium
    div.aigis-debug Medium margin applied on top and bottom
```
```jade
div.margin-box.-h-medium
    div.aigis-debug Medium margin applied on right and left
```

## Variants for auto margins

```jade
div.margin-box.-auto.-h(style="width: 200px")
    div.aigis-debug Auto margin applied on right and left
```
```jade
div.margin-box.-auto.-v(style="height: 46px")
    div.aigis-debug Auto margin applied on top and bottom
```

*/
.margin-box.-top {
  margin-top: 1.25rem;
}
.margin-box.-right {
  margin-right: 1.25rem;
}
.margin-box.-bottom {
  margin-bottom: 1.25rem;
}
.margin-box.-left {
  margin-left: 1.25rem;
}
.margin-box.-h {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}
.margin-box.-v {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.margin-box.-all {
  margin: 1.25rem 1.25rem;
}
.margin-box.-top-small {
  margin-top: 0.5rem;
}
.margin-box.-right-small {
  margin-right: 0.5rem;
}
.margin-box.-bottom-small {
  margin-bottom: 0.5rem;
}
.margin-box.-left-small {
  margin-left: 0.5rem;
}
.margin-box.-h-small {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.margin-box.-v-small {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.margin-box.-all-small {
  margin: 0.5rem 0.5rem;
}
.margin-box.-top-x-medium {
  margin-top: 1.25rem;
}
.margin-box.-right-x-medium {
  margin-right: 1.25rem;
}
.margin-box.-bottom-x-medium {
  margin-bottom: 1.25rem;
}
.margin-box.-left-x-medium {
  margin-left: 1.25rem;
}
.margin-box.-h-x-medium {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}
.margin-box.-v-x-medium {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.margin-box.-all-x-medium {
  margin: 1.25rem 1.25rem;
}
.margin-box.-top-medium {
  margin-top: 1.875rem;
}
.margin-box.-right-medium {
  margin-right: 1.875rem;
}
.margin-box.-bottom-medium {
  margin-bottom: 1.875rem;
}
.margin-box.-left-medium {
  margin-left: 1.875rem;
}
.margin-box.-h-medium {
  margin-left: 1.875rem;
  margin-right: 1.875rem;
}
.margin-box.-v-medium {
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;
}
.margin-box.-all-medium {
  margin: 1.875rem 1.875rem;
}
.margin-box.-top-large {
  margin-top: 3.75rem;
}
.margin-box.-right-large {
  margin-right: 3.75rem;
}
.margin-box.-bottom-large {
  margin-bottom: 3.75rem;
}
.margin-box.-left-large {
  margin-left: 3.75rem;
}
.margin-box.-h-large {
  margin-left: 3.75rem;
  margin-right: 3.75rem;
}
.margin-box.-v-large {
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;
}
.margin-box.-all-large {
  margin: 3.75rem 3.75rem;
}
.margin-box.-top-auto {
  margin-top: auto;
}
.margin-box.-right-auto {
  margin-right: auto;
}
.margin-box.-bottom-auto {
  margin-bottom: auto;
}
.margin-box.-left-auto {
  margin-left: auto;
}
.margin-box.-h-auto {
  margin-left: auto;
  margin-right: auto;
}
.margin-box.-v-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.margin-box.-all-auto {
  margin: auto auto;
}
.margin-box.-auto.-v {
  margin-top: auto;
  margin-bottom: auto;
}
.margin-box.-auto.-h {
  margin-left: auto;
  margin-right: auto;
}
/*
---
name: .padding-box
category: Box/Padding
tag:
 - component
 - layout
compile: true
---

A simple component to add paddings

## Applying paddings

The easiest use case is to use simple `.padding-box .-all` for a padding on top, right, bottom and left with default size or
`.-top`, `.-right`, `.-bottom` and `.-left` for specified sides

```jade
div.aigis-debug.padding-box.-all Defaut padding applied on top, right, bottom and left
```
```jade
div.aigis-debug.padding-box.-bottom Defaut padding applied on bottom
```

## Variants

The variants for the paddings are `.-top-{size}`, `.-right-{size}`, `.-bottom-{size}` and `.left-{size}` together with sizes small, medium or large.
All sizes are defined in the variable theme.components.padding-box. Values are `small`, `medium`, and `large`

```jade
div.aigis-debug.padding-box.-left-small Small padding applied on left
```
```jade
div.aigis-debug.padding-box.-right-small(style="text-align:right") Small padding applied on right
```
```jade
div.aigis-debug.padding-box.-top-small Small padding applied on top
```
```jade
div.aigis-debug.padding-box.-bottom-small Small padding applied on bottom
```

There are also shortcuts for vertical `.-v-{size}` and horizontal `.-h-{size}` together with sizes

```jade
div.aigis-debug.padding-box.-v-medium Medium padding applied on top and bottom
```
```jade
div.aigis-debug.padding-box.-h-medium(style="width: 300px") Medium padding applied on right and left
```
```jade
div.aigis-debug.padding-box.-h-medium.-top-large(style="width: 400px") Medium padding applied on right and left, large padding applied on top
```
*/
.padding-box.-top {
  padding-top: 0.5rem;
}
.padding-box.-right {
  padding-right: 0.5rem;
}
.padding-box.-bottom {
  padding-bottom: 0.5rem;
}
.padding-box.-left {
  padding-left: 0.5rem;
}
.padding-box.-h {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.padding-box.-v {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.padding-box.-all {
  padding: 0.5rem 0.5rem;
}
.padding-box.-top-small {
  padding-top: 0.125rem;
}
.padding-box.-right-small {
  padding-right: 0.125rem;
}
.padding-box.-bottom-small {
  padding-bottom: 0.125rem;
}
.padding-box.-left-small {
  padding-left: 0.125rem;
}
.padding-box.-h-small {
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}
.padding-box.-v-small {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}
.padding-box.-all-small {
  padding: 0.125rem 0.125rem;
}
.padding-box.-top-medium {
  padding-top: 1rem;
}
.padding-box.-right-medium {
  padding-right: 1rem;
}
.padding-box.-bottom-medium {
  padding-bottom: 1rem;
}
.padding-box.-left-medium {
  padding-left: 1rem;
}
.padding-box.-h-medium {
  padding-left: 1rem;
  padding-right: 1rem;
}
.padding-box.-v-medium {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.padding-box.-all-medium {
  padding: 1rem 1rem;
}
.padding-box.-top-large {
  padding-top: 2rem;
}
.padding-box.-right-large {
  padding-right: 2rem;
}
.padding-box.-bottom-large {
  padding-bottom: 2rem;
}
.padding-box.-left-large {
  padding-left: 2rem;
}
.padding-box.-h-large {
  padding-left: 2rem;
  padding-right: 2rem;
}
.padding-box.-v-large {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.padding-box.-all-large {
  padding: 2rem 2rem;
}
.padding-box.-top-x-large {
  padding-top: 4.8125rem;
}
.padding-box.-right-x-large {
  padding-right: 4.8125rem;
}
.padding-box.-bottom-x-large {
  padding-bottom: 4.8125rem;
}
.padding-box.-left-x-large {
  padding-left: 4.8125rem;
}
.padding-box.-h-x-large {
  padding-left: 4.8125rem;
  padding-right: 4.8125rem;
}
.padding-box.-v-x-large {
  padding-top: 4.8125rem;
  padding-bottom: 4.8125rem;
}
.padding-box.-all-x-large {
  padding: 4.8125rem 4.8125rem;
}
.padding-box.-gutter {
  padding: 0.9375rem 0.9375rem;
}
/*
---
name: .shadow-box
category: Box/Shadow
tag:
 - component
 - shadow
 - helper
compile: true
---

A box to control box-shadows with different variants

## Default variants
```jade
div.shadow-box Demo content
div.shadow-box.-inset Demo content
div.shadow-box.-zero Use zero shadow in animations
```
*/
.shadow-box {
  box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.17);
}
.shadow-box.-inset {
  box-shadow: inset 0px 1px 6px 0px rgba(0,0,0,0.17);
}
.shadow-box.-zero {
  box-shadow: inset 0px 0px 0px 0px rgba(0,0,0,0.17);
}
/*
---
name: .transform-box
category: Box/Transform
tag:
 - component
 - tranform
 - helper
compile: true
---

A box to control common transformations

## Rotate
```jade
div.transform-box.-rot90 Some demo content
div.transform-box.-rot180 Some demo content
div.transform-box.-rot270 Some demo content
```

## Flip
```jade
div.transform-box.-flipX Some demo content
div.transform-box.-flipY Some demo content
```
*/
.transform-box.-rot90 {
  transform: rotate(90deg);
}
.transform-box.-rot180 {
  transform: rotate(180deg);
}
.transform-box.-rot270 {
  transform: rotate(270deg);
}
.transform-box.-flipX {
  transform: scaleX(-1);
}
.transform-box.-flipY {
  transform: scaleY(-1);
}
/*
---
name: .button-style
category: Components/Button
tag:
 - component
 - button
compile: true
---

A button component as a base for different button variants defined in its configuration.

```jade
button.button-style.-main Action
```
*/
.button-style {
  appearance: none;
  background: transparent;
  border: none;
  display: inline-block;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.button-style.-main {
  color: #fff;
  background-color: #73cfa4;
  padding: 0.125rem 0.5rem;
}
.button-style.-main-mini {
  font-size: 0.8125rem;
  line-height: 1.125rem;
  height: 1.875rem;
  min-width: inherit;
  margin-right: 0.625rem;
  margin-bottom: 0.5rem;
  border-color: #73cfa4;
  background-color: #73cfa4;
  color: #fff;
}
.button-style.-main-mini:hover {
  border-color: #73cfa4;
  background-color: #81d4ad;
}
.button-style.-mini {
  font-size: 0.8125rem;
  line-height: 1.125rem;
  height: 1.875rem;
  min-width: inherit;
  margin-right: 0.625rem;
  margin-bottom: 0.5rem;
  border-color: #2d2d2d;
  border-width: 1px;
  max-width: 100%;
  overflow: hidden;
}
.button-style.-mini:hover {
  border-color: #73cfa4;
}
.button-style.-active-mini {
  color: #fff;
  background-color: #73cfa4;
  border: 1px solid #73cfa4;
}
.button-style.-active {
  color: #fff;
  background-color: #73cfa4;
  padding: 0.125rem 0.5rem;
}
.button-style.-hover {
  transition: all 0.1s;
}
.button-style.-hover:hover {
  background-color: #73cfa4;
}
/*
---
name: .drop-down-menu
category: DropDown
tag:
 - component
 - dropdown
compile: true
---

This styles a menu inside a .drop-down.

```jade
div.drop-down-menu
	div.item Menu 1
	div.item Menu 2
	div.item Menu 3
```

Used in combination with the .drop-down itself
```jade
div.drop-down.-open
	div.toggle.aigis-debug Toggle
	div.content.drop-down-menu
		div.item Menu 1
		div.item Menu 2
		div.item Menu 3
```

*/
.drop-down-menu {
  box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.17);
  border: 1px solid #e0e0e0;
  border-top: none;
}
.drop-down-menu > .item + .item {
  border-top: 1px solid #e0e0e0;
}
/*
---
name: .drop-down
category: DropDown
tag:
 - component
 - dropdown
compile: true
---

This dropdown has the simple mechanism to open and close a dropdown content.

Trigger with mouseover by adding `.-hover` variant

Trigger with js by adding `.-open` variant 

Closed Dropdown
```jade
div.drop-down
	div.toggle.aigis-debug Toggle
	div.content Dropdown content
```
## Variants

Variants `.-top`, `.-bottom`, `.-left` and `.-right` can be combined

Bottom left
```jade
div.drop-down.-hover.-bottom.-left
	div.toggle.aigis-debug Toggle
	div.content Dropdown content
```

Bottom right
```jade
div.drop-down.-hover.-bottom.-right
	div.toggle.aigis-debug Toggle
	div.content Dropdown content
```

Top right
```jade
div.drop-down.-hover.-top.-right
	div.toggle.aigis-debug Toggle
	div.content Dropdown content
```

Top left
```jade
div.drop-down.-hover.-top.-left
	div.toggle.aigis-debug Toggle
	div.content Dropdown content
```
## Aside

There is an `.-aside` variant to open content aside

Top left aside
```jade
div.drop-down.-hover.-top.-left.-aside(style="left:200px;width:50%")
	div.toggle.aigis-debug Toggle
	div.content Dropdown content
```

Top right aside
```jade
div.drop-down.-hover.-top.-right.-aside(style="width:50%")
	div.toggle.aigis-debug Toggle
	div.content Dropdown content
```
## Relative content

There is a variant for opening the content relative instead of absolute

Relative
```jade
div.drop-down.-hover.-relative(style="width:50%")
	div.toggle.aigis-debug Toggle
	div.content Dropdown content
```

JS Trigger
```jade
div.drop-down.-open.-bottom.-left
	div.toggle.aigis-debug Toggle
	div.content Dropdown content
```

## Do not wrap content

A variant preventing the content from wrapping


```jade
div.drop-down.-hover.-nowrap
	div.toggle.aigis-debug Toggle
	div.content Dropdown content with some whitespaces
```

*/
.drop-down {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.drop-down.-open > .content {
  display: block;
}
.drop-down.-hover:hover > .content {
  display: block;
}
.drop-down.-top > .content {
  bottom: 100%;
}
.drop-down.-bottom > .content {
  top: 100%;
}
.drop-down.-left > .content {
  left: 0;
}
.drop-down.-top.-left.-aside > .content {
  transform: translate(-100%, 100%);
}
.drop-down.-right > .content {
  right: 0;
}
.drop-down.-top.-right.-aside > .content {
  transform: translate(100%, 100%);
}
.drop-down.-relative > .content {
  position: relative;
}
.drop-down.-nowrap > .content {
  white-space: nowrap;
}
.drop-down > .toggle {
  display: inline-block;
}
.drop-down > .content {
  position: absolute;
  z-index: 1000;
  display: none;
}
.svg-icon {
  fill: currentColor;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
}
.svg-icon.-inline {
  display: inline-block;
}
.svg-icon.-medium {
  height: 1.25rem;
  width: 1.25rem;
}
.svg-icon.-small {
  height: 1rem;
}
.svg-icon.-v-centered {
  vertical-align: middle;
}
.svg-icon.-v-sub {
  vertical-align: sub;
}
.svg-icon.-scale {
  width: 100%;
  height: 100%;
}
/*
---
name: .abs-layout
category: Layout/Absolute
tag:
 - component
 - layout
compile: true
---

A simple component to position an element absolute. Default is top, left

## Variants

The variants for the vertical positioning are `.-top`, `.-vcenter` and `.-bottom`.
The variants for the horizontal positioning are `.-right`, `.-hcenter` and `.-left`.
Additinally the `-center` variant will center horizontal and vertical.
Variants to stretch an item to its full width/height can be dine with `.item.-stretch`.
In some situations the abs-layout itself needs to stretch so use the stretch variant on the component. `.abs-layout.-stretch` 

```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-center
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-top.-hcenter
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-top.-right
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-right.-vcenter
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-right.-bottom
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-bottom.-hcenter
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-bottom.-left
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-left.-vcenter
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-left.-top
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-stretch
```

## Z-Index

Every item can controll its z index with the `.-z{i}`variants.
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-top.-left.-z1
    div.aigis-debug.item.-top.-left.-z2
```

*/
.abs-layout {
  position: relative;
}
.abs-layout.-stretch {
  width: 100%;
  height: 100%;
}
.abs-layout > .item {
  position: absolute;
}
.abs-layout > .item.-top {
  top: 0;
}
.abs-layout > .item.-right {
  right: 0;
}
.abs-layout > .item.-bottom {
  bottom: 0;
}
.abs-layout > .item.-left {
  left: 0;
}
.abs-layout > .item.-vcenter {
  top: 50%;
  transform: translateY(-50%);
}
.abs-layout > .item.-hcenter {
  left: 50%;
  transform: translateX(-50%);
}
.abs-layout > .item.-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.abs-layout > .item.-stretch {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.abs-layout > .item.-z1 {
  z-index: 1;
}
.abs-layout > .item.-z2 {
  z-index: 2;
}
.abs-layout > .item.-z3 {
  z-index: 3;
}
.abs-layout > .item.-z4 {
  z-index: 4;
}
.abs-layout > .item.-z5 {
  z-index: 5;
}
.abs-layout > .item.-z6 {
  z-index: 6;
}
.abs-layout > .item.-z7 {
  z-index: 7;
}
.abs-layout > .item.-z8 {
  z-index: 8;
}
.abs-layout > .item.-z9 {
  z-index: 9;
}
.abs-layout > .item.-z10 {
  z-index: 10;
}
/*
---
name: .fixed-layout
category: Layout/Fixed
tag:
 - container
---

Fixed container
You can position the container with the different variants `.-top`, `.-bottom`, `.-left`, `.-right`.
To span full width / height use `.-left` & `.-right` / `.-top` & `.-bottom`

Fixed top and full width

```jade
div.fixed-layout.-top.-left.-right.aigis-debug top left fullwidth
    
```

Fixed right and full height

```jade
div.fixed-layout.-top.-bottom.-right.aigis-debug top right fullheight
    
```

*/
.fixed-layout {
  position: fixed;
}
.fixed-layout.-top {
  top: 0;
}
.fixed-layout.-bottom {
  bottom: 0;
}
.fixed-layout.-left {
  left: 0;
}
.fixed-layout.-right {
  right: 0;
}
/*
---
name: .fixed-page-layout
category: Layout/Fixed Page
tag:
 - component
 - page
compile: true
---

A page layout with a fixed header and optional sidebars.
With the `-margin-{top|left|right}`, `-margin-{top|left|right}-small` variants you can control the spacing.
If the content for example needs a spacing towards the header use: `.content.-margin-top`.
If the sidebar show be below the header use: `.sidebar.-left.-margin-top`.


There are variants to resize the header and sidebars to a smaller width/height: `.header.-small`, `.sidebar.-small`.
If you need a spacing on your content or sidebar you need to adjust that manually like this:
```
div.fixed-page-layout
	div.header.-small
		div.aigis-debug(style="width:100%;height:100%")  Header
	div.content.-margin-top-small Content
	div.sidebar.-left.-margin-top-small.-small
		div.aigis-debug(style="height:100%")  Left Sidebar
```

## Header with sidebars

```jade
div.fixed-page-layout
	div.header
		div.aigis-debug(style="width:100%;height:100%")  Header
	div.content.-margin-top Content
	div.sidebar.-left.-margin-top
		div.aigis-debug(style="height:100%")  Left Sidebar
	div.sidebar.-right
		div.aigis-debug(style="height:100%")  Right Sidebar
```
*/
.fixed-page-layout > .header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 4.375rem;
  transition: all 0.2s;
  z-index: 1000;
}
.fixed-page-layout > .header.-small {
  height: 1.875rem;
}
.fixed-page-layout > .header.-hide {
  transform: translateY(-100%);
}
.fixed-page-layout > .sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 14.5625rem;
  transition: all 0.2s;
  z-index: 1005;
}
.fixed-page-layout > .sidebar.-margin-top {
  top: 4.375rem;
}
.fixed-page-layout > .sidebar.-margin-top-small {
  top: 1.875rem;
}
.fixed-page-layout > .sidebar.-small {
  width: 3.125rem;
}
.fixed-page-layout > .sidebar.-left {
  left: 0;
}
.fixed-page-layout > .sidebar.-left.-hide {
  transform: translateX(-100%);
}
.fixed-page-layout > .sidebar.-right {
  right: 0;
}
.fixed-page-layout > .sidebar.-right.-hide {
  transform: translateX(100%);
}
.fixed-page-layout > .content {
  transition: margin 0.2s;
}
.fixed-page-layout > .content.-margin-left {
  margin-left: 14.5625rem;
}
.fixed-page-layout > .content.-margin-left-small {
  margin-left: 3.125rem;
}
.fixed-page-layout > .content.-margin-right {
  margin-right: 14.5625rem;
}
.fixed-page-layout > .content.-margin-right-small {
  margin-right: 3.125rem;
}
.fixed-page-layout > .content.-margin-top {
  margin-top: 4.375rem;
}
.fixed-page-layout > .content.-margin-top-small {
  margin-top: 1.875rem;
}
/*
---
name: .flex-layout
category: Layout/Flex
tag:
 - component
 - layout
 - flexbox
compile: true
---

A layout system based on flexbox to align and distribute items horizontally and vertically.


# Alignment of items

The `-center` shortcut will center all items horizontal & vertical
```jade
div(style="height: 100px").flex-layout.-center
	div.aigis-debug Item 1
	div.aigis-debug Item 2
	div.aigis-debug Item 3
```

`-baseline` will align items based on their baseline. Works good if all the items contain text. 
```jade
div.flex-layout.-baseline
	div.aigis-debug Item
	div.aigis-debug(style="padding-top: 50px;padding-bottom: 30px;") Item 2
```

`-items-top` & `-items-bottom` will align the items either on top or bottom
```jade
div.flex-layout.-items-top
	div.aigis-debug Item 1
	div.aigis-debug(style="padding-top: 50px;padding-bottom: 30px;") Item 2
	div.aigis-debug Item 3
```
```jade
div.flex-layout.-items-bottom
	div.aigis-debug Item 1
	div.aigis-debug(style="padding-top: 50px;padding-bottom: 30px;") Item 2
	div.aigis-debug Item 3
```

`-items-center` will center the items vertically
```jade
div.flex-layout.-items-center
	div.aigis-debug Item 1
	div.aigis-debug(style="padding-top: 50px;padding-bottom: 30px;") Item 2
	div.aigis-debug Item 3
```

# Alignment of individual items

```jade
div(style="height: 100px").flex-layout
	div.aigis-debug.item.-top top
	div.aigis-debug.item.-center center
	div.aigis-debug.item.-bottom bottom
	div.aigis-debug.item.-stretch stretch
```

# Row mode

If you like a container that spans the full width with item that resize themself you can use the `-row` variant.

```jade
div.flex-layout.-row
	div.aigis-debug.item Item 1
	div.aigis-debug.item Item 2
	div.aigis-debug.item Item 3
```

If you like to fix an item based on its content width use the `-content` variant.
This is a shortcut for `-no-grow -no-shrink`. 
```jade
div.flex-layout.-row
	div.aigis-debug.item.-content
		img(src="http://via.placeholder.com/350x80")
	div.aigis-debug.item Item 2
	div.aigis-debug.item Item 3
```

You can control the sizing of the items with `-grow`, `-shrink`, `-no-grow`, `-no-shrink`.
```jade
div.flex-layout.-row
	div.aigis-debug.item.-no-shrink
		img(src="http://via.placeholder.com/350x80")
	div.aigis-debug.item.-no-grow Item 2
	div.aigis-debug.item Item 3
```


# Distribution of items

Use `-[v/h]-space-around` & `-[v/h]-space-between` to distribute the items in the flex container.

```jade
div.flex-layout.-h-space-around
	div.aigis-debug Item 1
	div.aigis-debug Item 2
	div.aigis-debug Item 3
```
```jade
div.flex-layout.-h-space-between
	div.aigis-debug Item 1
	div.aigis-debug Item 2
	div.aigis-debug Item 3
```
```jade
div(style="height: 200px").flex-layout.-v-space-around.-wrap
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```
```jade
div(style="height: 200px").flex-layout.-v-space-between.-wrap
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```

# Alignment of multiline items with wrap

If you have alot on items that wrap you can align the multilines with the following variants:
```jade
div.flex-layout.-wrap.-h-center
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```
```jade
div.flex-layout.-wrap.-left
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```
```jade
div.flex-layout.-wrap.-right
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```
```jade
div(style="height: 200px").flex-layout.-wrap.-v-center
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```
```jade
div(style="height: 200px").flex-layout.-wrap.-top
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```
```jade
div(style="height: 200px").flex-layout.-wrap.-bottom
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```




*/
.flex-layout {
  display: flex;
}
.flex-layout.-inline {
  display: flex-inline;
}
.flex-layout.-wrap {
  flex-wrap: wrap;
}
.flex-layout.-row {
  flex-wrap: nowrap;
}
.flex-layout.-row > .item {
  flex: 1 1 auto;
}
.flex-layout.-column {
  flex-direction: column;
}
.flex-layout.-center {
  justify-content: center;
  align-items: center;
  align-content: center;
}
.flex-layout.-baseline {
  align-items: baseline;
}
.flex-layout.-left {
  justify-content: flex-start;
}
.flex-layout.-right {
  justify-content: flex-end;
}
.flex-layout.-top {
  align-content: flex-start;
}
.flex-layout.-bottom {
  align-content: flex-end;
}
.flex-layout.-v-center {
  align-content: center;
}
.flex-layout.-v-space-between {
  align-content: space-between;
}
.flex-layout.-v-space-around {
  align-content: space-around;
}
.flex-layout.-h-center {
  justify-content: center;
}
.flex-layout.-h-space-between {
  justify-content: space-between;
}
.flex-layout.-h-space-around {
  justify-content: space-around;
}
.flex-layout.-items-top {
  align-items: flex-start;
}
.flex-layout.-items-bottom {
  align-items: flex-end;
}
.flex-layout.-items-center {
  align-items: center;
}
.flex-layout.-items-stretch {
  align-items: flex-stretch;
}
.flex-layout > .item.-stretch {
  align-self: stretch;
}
.flex-layout > .item.-top {
  align-self: flex-start;
}
.flex-layout > .item.-center {
  align-self: center;
}
.flex-layout > .item.-bottom {
  align-self: flex-end;
}
.flex-layout > .item.-content {
  flex: none;
}
.flex-layout > .item.-grow {
  flex-grow: 1;
}
.flex-layout > .item.-shrink {
  flex-shrink: 1;
}
.flex-layout > .item.-no-grow {
  flex-grow: 0;
}
.flex-layout > .item.-no-shrink {
  flex-shrink: 0;
}
/*
---
name: .grid-layout
category: Layout/Grid
tag:
 - component
 - layout
 - grid
 - responsive
 - flexbox
compile: true
---

A grid layout system based on flexbox. It provides sizing variants to size on different percentages.


## Percentage based sizing

The easiest use case is to use simple `.item -w1`, `.item -w1-2`, `.item -w1-3`, `.item -w1-4` for a percentage based side-by-side rendering.

```jade
div.grid-layout
	div.item.-w1
		div.aigis-debug item -w1
	div.item.-w1-2 
		div.aigis-debug item -w1-2
	div.item.-w1-2
		div.aigis-debug item -w1-2
	div.item.-w1-3
		div.aigis-debug item -w1-3
	div.item.-w2-3
		div.aigis-debug item -w2-3
	div.item.-w1-4
		div.aigis-debug item -w1-4
	div.item.-w1-2
		div.aigis-debug item -w1-2
	div.item.-w1-4
		div.aigis-debug item -w1-4
```

## Layouts on different devices

The breakpoints for different screen sizes are specified in the global settings `theme.settings.breakpoints` and can be overwritten in `theme.components.grid-layout.breakpoints`.
The default settings define breakpoints with labels: `.-small-X-X`, `.-medium-X-X`, `.-large-X-X`.

```jade
div.grid-layout
	div.item.-w1.-small-1-3.-medium-1-2.-large-1-4
		div.aigis-debug item -w1 -small-1-3 -medium-1-2 -large-1-4
	div.item.-w1.-small-1-3.-medium-1-2.-large-1-4
		div.aigis-debug item -w1 -small-1-3 -medium-1-2 -large-1-4
	div.item.-w1-2.-small-1-3.-medium-1-2.-large-1-4
		div.aigis-debug item -w1-2 -small-1-3 -medium-1-2 -large-1-4
	div.item.-w1-2.-small-1.-medium-1-2.-large-1-4
		div.aigis-debug item -w1-2 -small-1 -medium-1-2 -large-1-4
```


## Gutter

If you need a spacing between the columns add the `-gutter` variant.
The size of the gutter is specified in the `grid-gutter`variable.

```jade
div.grid-layout.-gutter
	div.item.-w1-4
		div.aigis-debug col -w1-4
	div.item.-w1-4
		div.aigis-debug col -w1-4
	div.item.-w1-2
		div.aigis-debug col -w1-2
```


## Reverse order

To reverse the order in which the columns are displayed, you can add the modifier class `.-reverse` to your table-grid.
The columns will be in DOM order when viewport switches to small layout.

```jade
div.grid-layout.-reverse
	div.item.-w1-4
		div.aigis-debug First column in DOM
	div.item.-w1-4
		div.aigis-debug Second column in DOM
	div.item.-w1-4
		div.aigis-debug Third column in DOM
	div.item.-w1-4
		div.aigis-debug Fourth column in DOM
```

## Vertical centering

You can vertical center with the help of the `flex-layout` component.

```jade
div.grid-layout.flex-layout
	div.item.-w1-4
		div.aigis-debug A column with a lot of content that should wrap on fixed size.
	div.item.-bottom
		div.aigis-debug bottom
	div.item.-center
		div.aigis-debug center
	div.item.-stretch
		div.aigis-debug(style="height:100%") stretch
```

*/
.grid-layout {
  display: flex;
  flex-wrap: wrap;
}
.grid-layout.-gutter {
  margin: -0.625rem -0.9375rem;
}
.grid-layout.-gutter > .item {
  padding: 0.625rem 0.9375rem;
}
.grid-layout.-reverse {
  flex-direction: row-reverse;
}
.grid-layout > .item.-w1 {
  flex: 0 0 100%;
  max-width: 100%;
}
.grid-layout > .item.-w1-2 {
  flex: 0 0 50%;
  max-width: 50%;
}
.grid-layout > .item.-w1-3 {
  flex: 0 0 33.333333333333336%;
  max-width: 33.333333333333336%;
}
.grid-layout > .item.-w2-3 {
  flex: 0 0 66.66666666666667%;
  max-width: 66.66666666666667%;
}
.grid-layout > .item.-w1-4 {
  flex: 0 0 25%;
  max-width: 25%;
}
.grid-layout > .item.-w3-4 {
  flex: 0 0 75%;
  max-width: 75%;
}
@media all and (min-width: 32.5rem) {
  .grid-layout > .item.-xsmall-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .grid-layout > .item.-xsmall-1-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid-layout > .item.-xsmall-1-3 {
    flex: 0 0 33.333333333333336%;
    max-width: 33.333333333333336%;
  }
  .grid-layout > .item.-xsmall-2-3 {
    flex: 0 0 66.66666666666667%;
    max-width: 66.66666666666667%;
  }
  .grid-layout > .item.-xsmall-1-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .grid-layout > .item.-xsmall-3-4 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}
@media all and (min-width: 48rem) {
  .grid-layout > .item.-small-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .grid-layout > .item.-small-1-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid-layout > .item.-small-1-3 {
    flex: 0 0 33.333333333333336%;
    max-width: 33.333333333333336%;
  }
  .grid-layout > .item.-small-2-3 {
    flex: 0 0 66.66666666666667%;
    max-width: 66.66666666666667%;
  }
  .grid-layout > .item.-small-1-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .grid-layout > .item.-small-3-4 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}
@media all and (min-width: 62rem) {
  .grid-layout > .item.-medium-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .grid-layout > .item.-medium-1-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid-layout > .item.-medium-1-3 {
    flex: 0 0 33.333333333333336%;
    max-width: 33.333333333333336%;
  }
  .grid-layout > .item.-medium-2-3 {
    flex: 0 0 66.66666666666667%;
    max-width: 66.66666666666667%;
  }
  .grid-layout > .item.-medium-1-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .grid-layout > .item.-medium-3-4 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}
@media all and (min-width: 75rem) {
  .grid-layout > .item.-large-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .grid-layout > .item.-large-1-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid-layout > .item.-large-1-3 {
    flex: 0 0 33.333333333333336%;
    max-width: 33.333333333333336%;
  }
  .grid-layout > .item.-large-2-3 {
    flex: 0 0 66.66666666666667%;
    max-width: 66.66666666666667%;
  }
  .grid-layout > .item.-large-1-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .grid-layout > .item.-large-3-4 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}
/*
---
name: .max-width-layout
category: Layout/Page
tag:
 - component
 - layout
 - responsive
compile: true
---

This component will limit its content to a maximum width. Forthermore it can center its content.
Usefull for page content that should not span the full width.


## Percentage based sizing

*/
@media all and (min-width: 48rem) {
  .max-width-layout {
    max-width: 41.75rem;
  }
}
@media all and (min-width: 62rem) {
  .max-width-layout {
    max-width: 43.75rem;
  }
}
@media all and (min-width: 75rem) {
  .max-width-layout {
    max-width: 51.4375rem;
  }
}
.max-width-layout.-center {
  margin-left: auto;
  margin-right: auto;
}
/*
---
name: .slide-overlay
category: Overlay/Slide
tag:
 - rollover
 - slide
 - overlay
---

Rollover component - content rollover rolls in - default from bottom

Trigger with mouserover by adding `.-hover` variant

Trigger with js by adding `.-show` variant

```jade
div.slide-overlay.-hover
    div Some main content
    div.rollover Rollover content
```

Rollover component variant -left
```jade
div.slide-overlay.-left.-hover
    div Some main content
    div.rollover Rollover content
```

Rollover component variant -right
```jade
div.slide-overlay.-right.-hover
    div Some main content
    div.rollover Rollover content
```

Rollover component variant -top
```jade
div.slide-overlay.-top.-hover
    div Some main content
    div.rollover Rollover content
```

Rollover component variant -top with js trigger
```jade
div.slide-overlay.-top.-show
    div Some main content
    div.rollover Rollover content
```
*/
.slide-overlay {
  position: relative;
  overflow: hidden;
}
.slide-overlay > .rollover {
  position: absolute;
  bottom: 0;
  transform: translate(0, 100%);
  transition: transform 0.5s ease;
}
.slide-overlay.-left > .rollover {
  transform: translate(-100%, 0);
  left: 0;
}
.slide-overlay.-right > .rollover {
  transform: translate(100%, 0);
  right: 0;
}
.slide-overlay.-top > .rollover {
  transform: translate(0, -100%);
  top: 0;
}
.slide-overlay.-show > .rollover {
  transform: translate(0, 0);
}
.slide-overlay.-hover:hover > .rollover {
  transform: translate(0, 0);
}
/*
---
name: .control-responsive
category: Responsive/Control
tag:
 - responsive
 - display
compile: true
---

Controls the display of an element depending on the current breakpoint

## Hide

`.-hide-medium` hides an element as long as the screen is wider than the breakpoint `medium`.

```jade
div.control-responsive.-hide-medium Only shown when the screen is wider than the breakpoint medium
```

## Show

`.-show-medium` show an element as long as the screen is narrower than the breakpoint `medium`.

```jade
div.control-responsive.-show-medium Only shown when the screen is narrower than the breakpoint `medium`
```

*/
@media all and (max-width: 32.5rem) {
  .control-responsive.-hide-xsmall {
    display: none;
  }
}
@media all and (min-width: 32.5rem) {
  .control-responsive.-show-xsmall {
    display: none;
  }
}
@media all and (max-width: 48rem) {
  .control-responsive.-hide-small {
    display: none;
  }
}
@media all and (min-width: 48rem) {
  .control-responsive.-show-small {
    display: none;
  }
}
@media all and (max-width: 62rem) {
  .control-responsive.-hide-medium {
    display: none;
  }
}
@media all and (min-width: 62rem) {
  .control-responsive.-show-medium {
    display: none;
  }
}
@media all and (max-width: 75rem) {
  .control-responsive.-hide-large {
    display: none;
  }
}
@media all and (min-width: 75rem) {
  .control-responsive.-show-large {
    display: none;
  }
}
@media all and (max-width: 32.5rem) {
  .hide-responsive.-xsmall {
    display: none;
  }
}
@media all and (max-width: 48rem) {
  .hide-responsive.-small {
    display: none;
  }
}
@media all and (max-width: 62rem) {
  .hide-responsive.-medium {
    display: none;
  }
}
@media all and (max-width: 75rem) {
  .hide-responsive.-large {
    display: none;
  }
}
/*
---
name: .image-responsive
category: Responsive/Image
tag:
 - responsive
compile: true
---

This class makes the image scaling to its max width.

```jade
img.image-responsive(src='http://www.byui.edu/images/agriculture-life-sciences/flower.jpg')
```

## Variants

`.-full` forces the picture to span to 100% of the parent

```jade
img.image-responsive.-full(src='http://www.byui.edu/images/agriculture-life-sciences/flower.jpg')
```
*/
.image-responsive {
  max-width: 100%;
  height: auto;
  display: block;
}
.image-responsive.-full {
  width: 100%;
}
.image-responsive.-inset {
  max-height: 100%;
}
/*
---
name: .media-responsive
category: Responsive/Media
tag:
 - responsive
compile: true
---

`.media-responsive` - This makes a the `.media-responsive` div a container for any media element (iframe, video, ... ) that responsive scales with a fix ratio.
The media element ( child ) needs the class `.media` to fully fill out the parents size.

The `.media-responsive` component has to predefined modifiers: `.-media-4-3`, `.-media-16-9` that define a fix ratio.

```jade
div.media-responsive.-media-16-9
	iframe.media(src="https://www.youtube.com/embed/TCd6PfxOy0Y")
```

```jade
div.media-responsive.-media-4-3
	iframe.media(src="https://www.youtube.com/embed/TCd6PfxOy0Y")
```

*/
.media-responsive {
  width: 100%;
  position: relative;
  padding-bottom: 50%;
}
.media-responsive.-media-1-1 {
  padding-bottom: 50%;
}
.media-responsive.-media-4-3 {
  padding-bottom: 75%;
}
.media-responsive.-media-16-9 {
  padding-bottom: 56.25%;
}
.media-responsive > .media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/*
---
name: .overflow-text
category: Text
tag:
 - component
 - text
compile: true
---

This component cuts the text on a given height and adds a fadeout effect.
This is usefull for text containers that have more content than space.


```jade
div.overflow-text(style="width: 200px;height: 70px") This is a very long text that is rwaped and cut at the end.
```
*/
.overflow-text {
  overflow: hidden;
  position: relative;
}
.overflow-text:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 2.1875rem;
  background: linear-gradient(to bottom, rgba(255,255,255,0), #fff 70%);
}
/*
---
name: .text-style
category: Text
tag:
 - component
 - text
compile: true
---

A text styling component for different text styles

## Default heading

```jade
div.text-style Default heading
```

## H1
```jade
div.text-style.-h1 The h1 header

```

## H2
```jade
div.text-style.-h2 The h2 header
```

## H3
```jade
div.text-style.-h3 The h3 header
```

## Bold
```jade
div.text-style.-bold Bold text
```

## Align center
```jade
div.text-style.-center Centered text
```

## Align right
```jade
div.text-style.-right Right aligned text
```

*/
.text-style.-h1 {
  font-family: 'Open Sans' , sans-serif;
  font-size: 3.375rem;
  line-height: 4.375rem;
}
.text-style.-h2 {
  font-family: 'Open Sans' , sans-serif;
  font-size: 2.25rem;
  line-height: 3.0625rem;
  font-weight: bold;
  letter-spacing: 0.5px;
  margin-top: 2rem;
  margin-bottom: 0.3125rem;
}
.text-style.-h3 {
  font-family: 'Open Sans' , sans-serif;
  font-weight: bold;
  font-size: 1.625rem;
  line-height: 1.75rem;
}
.text-style.-page-heading {
  font-family: 'Open Sans' , sans-serif;
  font-weight: bold;
  font-size: 1.625rem;
  line-height: 1.75rem;
}
.text-style.-h5 {
  font-family: 'Open Sans' , sans-serif;
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.875rem;
}
.text-style.-h4 {
  font-family: 'Open Sans' , sans-serif;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.375rem;
}
.text-style.-meta {
  font-size: 0.8125rem;
  line-height: 1.125rem;
  color: #a2a2a2;
}
.text-style.-section {
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 600;
  border-bottom: 2px solid #2d2d2d;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}
.text-style.-p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.text-style.-caption {
  font-size: 0.875rem;
}
.text-style.-center {
  text-align: center;
}
.text-style.-bold {
  font-weight: 700;
}
.text-style.-medium {
  font-weight: 500;
}
.collapse-ui > .content.-transition {
  transition: height 0.3s ease;
  overflow: hidden;
  height: 0;
}
.collapse-ui.-close > .content {
  display: none;
}
.collapse-ui.-close .collapse-icon {
  transform: rotate(90deg);
}
.collapse-ui .collapse-icon {
  transition: all 0.3s ease;
}
a {
  color: #2d2d2d;
  cursor: pointer;
  border-bottom: 0.125rem solid #73cfa4;
}
a.-undecorated {
  border-bottom: 0;
}
a:hover {
  color: #73cfa4;
}
.button-style {
  height: 2.8125rem;
  min-width: 7.5rem;
  display: inline-block;
  border: 2px solid #73cfa4;
}
.button-style:hover {
  background-color: #73cfa4;
  color: #fff;
}
.card-image {
  height: 12.5rem;
  background-size: cover;
  background-position: center;
}
.card-link {
  background: linear-gradient(rgba(255,255,255,0) 0%, #fff 20%);
}
.chapter-element {
  height: 5rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  cursor: pointer;
}
.chapter-element .state-indicator {
  transition: transform 0.3s;
}
.chapter-element.open .state-indicator {
  transform: rotateZ(180deg);
}
.boolean-slider {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 24px;
}
.boolean-slider input {
  display: none;
}
.slider-control {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider-control:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider-control:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}
.slider-control.round {
  border-radius: 34px;
}
.slider-control.round:before {
  border-radius: 50%;
}
.copyright {
  position: absolute;
  z-index: 100;
  right: 20px;
  top: 435px;
  color: #fff;
  opacity: 0.8;
  text-shadow: 0px 0px 12px #333;
  font-size: 14px;
}
[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer;
}
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  opacity: 0;
  pointer-events: none;
}
[data-tooltip]:before {
  position: absolute;
  top: 24px;
  right: 2px;
  margin-bottom: 5px;
  padding: 7px;
  width: 160px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #000;
  background-color: rgba(51,51,51,0.9);
  color: #fff;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
}
[data-tooltip]:after {
  position: absolute;
  top: 19px;
  left: 7px;
  width: 0;
  border-top: 5px solid #000;
  border-top: 5px solid rgba(51,51,51,0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
  transform: rotate(180deg);
}
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  opacity: 1;
}
.dialog-trigger {
  position: fixed;
  z-index: 99999;
  right: 1.25rem;
  bottom: 1.25rem;
  background-color: #73cfa4;
  color: #fff;
  height: 3rem;
  border-radius: 1.5rem;
  box-shadow: 0 0.5rem 0.75rem rgba(0,0,0,0.3);
}
.dialog-trigger:hover {
  background-color: #86d6b0;
  border-color: #86d6b0;
  box-shadow: 0 0.625rem 0.9375rem rgba(0,0,0,0.3);
}
.filter-term {
  background-color: #fff;
}
.filter-term .term-item {
  overflow: hidden;
}
.filter-term .term-item input {
  max-width: 100%;
  border: 0;
}
.full-height {
  height: 100%;
}
.goal-icon {
  width: 5.8125rem;
  height: 5.8125rem;
}
.header-icon {
  width: 4.375rem;
  border-right: 1px solid #e0e0e0;
  display: inline-block;
  color: #2d2d2d;
}
.header-icon:hover {
  color: #73cfa4;
  cursor: pointer;
}
.header-menu {
  min-width: 16.875rem;
  position: fixed;
  top: 3.4375rem;
  left: 3.75rem;
  padding: 1.25rem 2.5rem;
  background-color: #f6f0ea;
  border: 0.03125rem solid rgba(0,0,0,0.25);
  box-shadow: 0 0.1875rem 1.5rem rgba(0,0,0,0.2);
}
.hidden {
  display: none;
}
.v-stretch {
  height: 100%;
}
.h-stretch {
  width: 100%;
}
.icon-down {
  position: relative;
  top: 0.125rem;
}
.icon-taskdimensions {
  width: 2.375rem;
  height: 2.1875rem;
}
.icon-taskdimensions .oekonomie,
.icon-taskdimensions .oekologie,
.icon-taskdimensions .soziales {
  fill: #e6dacd;
}
.icon-taskdimensions.-show-oekonomie .oekonomie {
  fill: #8067b6;
}
.icon-taskdimensions.-show-oekologie .oekologie {
  fill: #75d6f4;
}
.icon-taskdimensions.-show-soziales .soziales {
  fill: #ed5f7c;
}
#taskdixmensions .oekonomie {
  display: none;
}
.input-style.-basic {
  border: 0;
  padding: 0.125rem 0.9375rem 0.3125rem;
}
.input-style.-maxwidth {
  width: 100%;
}
.extended-width-layout {
  max-width: 62.25rem;
}
.extended-width-layout.-center {
  margin-left: auto;
  margin-right: auto;
}
/*
---
name: .fixed-page-layout
category: Layout/Fixed Page
tag:
 - component
 - page
compile: true
---

A page layout with a fixed header and optional sidebars.
With the `-margin-{top|left|right}`, `-margin-{top|left|right}-small` variants you can control the spacing.
If the content for example needs a spacing towards the header use: `.content.-margin-top`.
If the sidebar show be below the header use: `.sidebar.-left.-margin-top`.


There are variants to resize the header and sidebars to a smaller width/height: `.header.-small`, `.sidebar.-small`.
If you need a spacing on your content or sidebar you need to adjust that manually like this:
```
div.fixed-page-layout
	div.header.-small
		div.aigis-debug(style="width:100%;height:100%")  Header
	div.content.-margin-top-small Content
	div.sidebar.-left.-margin-top-small.-small
		div.aigis-debug(style="height:100%")  Left Sidebar
```

## Header with sidebars

```jade
div.fixed-page-layout
	div.header
		div.aigis-debug(style="width:100%;height:100%")  Header
	div.content.-margin-top Content
	div.sidebar.-left.-margin-top
		div.aigis-debug(style="height:100%")  Left Sidebar
	div.sidebar.-right
		div.aigis-debug(style="height:100%")  Right Sidebar
```
*/
.fixed-page-layout > .content {
  min-height: calc( 100vh - 3.125rem);
}
.answer-view input[type="radio"] {
  margin-top: 4px;
  position: absolute;
}
.answer-view input[type="checkbox"] {
  margin-top: 4px;
  position: absolute;
}
.answer-view .text {
  margin-left: 1.5625rem;
}
.answer-view.-correct {
  background-color: #f1f9ee;
  color: #47882d;
}
.answer-view.-wrong {
  color: #9b2727;
  background-color: #fdf7f7;
}
.drag-drop .drag-container {
  background-color: grey-color;
}
.drag-drop .drag-node {
  border: 1px dashed #444;
  cursor: pointer;
  max-width: 60px;
}
.drag-drop .drop-node {
  width: 100%;
}
.drag-drop .drop-target {
  border: 1px dashed #444;
  min-height: 45px;
  width: 100%;
}
.drag-drop .drop-target >.drag-node {
  display: inline-block;
}
.feedback-container {
  display: inline-block;
}
.feedback-panel {
  line-height: 28px;
  padding: 2.5rem 1.875rem 1.25rem 1.875rem;
  margin: -1.5rem 0 0 0;
}
.feedback-panel.-correct {
  border: 1px solid #4bd2a1;
  background-color: #e0f5ee;
  color: #4bd2a1;
}
.feedback-panel.-wrong {
  border: 1px solid #d24b4b;
  background-color: #f5e2e2;
  color: #d24b4b;
}
.hotspot-point {
  height: 20px;
  width: 20px;
  border-radius: 12px;
  background-color: rgba(255,255,255,0.66);
  margin-left: -10px;
  margin-top: -10px;
}
.hotspot-rect {
  background-color: rgba(0,0,0,0.2);
  border: 1px solid #fff;
}
.link-style.-undecorated {
  text-decoration: none;
}
.link-style.-active {
  color: #73cfa4;
}
.material-card {
  width: 13.4375rem;
  height: 18.125rem;
  display: inline-block;
  overflow: hidden;
  font-size: 0.8125rem;
  line-height: 1.125rem;
  transition: box-shadow 0.2s;
}
.material-card img {
  object-fit: cover;
  width: 100%;
  height: 8.625rem;
}
.material-card > .content-container {
  position: relative;
  transition: all 0.3s;
  top: 0;
}
.material-card > .content-container > .copy {
  position: absolute;
  top: 143px;
  right: 2px;
}
.material-card.-hascover:hover > .content-container {
  top: -8.625rem;
}
.material-card:hover {
  cursor: pointer;
  box-shadow: 0px 2px 22px rgba(0,0,0,0.18);
}
.material-card:hover .card-meta {
  display: none;
}
.material-card:hover .card-action {
  display: block;
}
.material-card .card-action {
  display: none;
}
.material-card .card-action,
.material-card .card-meta,
.material-card .card-meta a {
  color: #a2a2a2 !important;
}
.material-sidebar {
  position: absolute !important;
  background-color: #f6f0ea;
  width: 15rem;
  top: 0.0625rem !important;
  z-index: 10 !important;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-bottom: -1.875rem;
}
.modal-background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100000;
  background-color: rgba(0,0,0,0.4);
}
.page-footer {
  background-color: #73cfa4;
  color: #fff;
  position: relative;
  z-index: 10000;
  height: 4.375rem;
}
.page-footer a {
  color: #fff;
}
.page-footer a:hover {
  color: #fff;
}
.page-footer img {
  height: 3rem;
}
.page-header {
  background-color: #fff;
  box-shadow: 0 1px 0 0 rgba(0,0,0,0.25);
  height: 4.375rem;
  z-index: 10000;
}
.progress-bar {
  background-image: linear-gradient(to right, #73cfa4 0%, #73cfa4 50%, #f6f0ea 0%, #f6f0ea 100%);
  background-position: 100% 0;
  background-size: 200% auto;
  width: 3.75rem;
  height: 1.125rem;
  transition: background-position 0.5s ease;
  border-radius: 0.5625rem;
}
.progress-bar .progress {
  background-color: #73cfa4;
  height: 1.125rem;
  border-radius: 0.5625rem;
  transition: all 0.3s ease;
  min-width: 1.125rem;
}
.progress-bar.-p0 .progress {
  width: 5%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.progress-bar.-p10 .progress {
  width: 10%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.progress-bar.-p20 .progress {
  width: 20%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.progress-bar.-p30 .progress {
  width: 30%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.progress-bar.-p40 .progress {
  width: 40%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.progress-bar.-p50 .progress {
  width: 50%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.progress-bar.-p60 .progress {
  width: 60%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.progress-bar.-p70 .progress {
  width: 70%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.progress-bar.-p80 .progress {
  width: 80%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.progress-bar.-p90 .progress {
  width: 90%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.progress-bar.-p100 .progress {
  width: 100%;
}
.state-dialog {
  position: fixed;
  z-index: 100100;
  top: 0;
  bottom: 0;
  right: 0;
  width: 31.25rem;
  background-color: #fff;
  box-shadow: 0 0 0.625rem 0 rgba(0,0,0,0.2);
}
.state-dialog .dialog-part {
  height: 405px;
  padding: 0.875rem 2.5rem;
}
.state-dialog .dialog-part.-dark {
  background-color: #f6f0ea;
  height: 100%;
}
.state-dialog .dialog-closer {
  position: absolute;
  top: 0.875rem;
  right: 0.875rem;
}
.swap-image {
  cursor: pointer;
  display: inline-block;
}
.swap-image.-active-mini .-passive {
  display: none;
}
.swap-image.-active-mini .-active {
  display: inline;
}
.swap-image .-passive {
  display: inline;
}
.swap-image .-active {
  display: none;
}
.task-checkbox {
  appearance: none;
}
.task-checkbox:before {
  content: ' ';
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.75rem;
  background-color: #fff;
  border: 0.125rem solid #b4b4b4;
  font-size: 1.4375rem;
  cursor: pointer;
}
.task-checkbox:checked:before {
  content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24'><path d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z' fill='white'/></svg>");
  color: #fff;
  background-color: #73cfa4;
  border-color: #73cfa4;
}
.task-listitem {
  padding: 1.875rem 2.5rem;
  background-color: #f6f0ea;
  border-bottom: 1px solid #fff;
}
.teaser-image {
  height: 25rem;
  background-size: cover;
  background-position: 15% 50%;
}
.thema-card {
  height: 25rem;
  position: relative;
  transition: box-shadow 0.2s;
}
.thema-card > .content-wrapper > .content {
  overflow-y: scroll;
  -ms-overflow-style: none;
  position: absolute;
  height: 22.125rem;
  top: 0px;
  transition: top 0.3s;
}
.thema-card > .link {
  height: 3.4375rem;
  width: 100%;
  position: absolute;
  bottom: 0px;
}
.thema-card:hover {
  cursor: pointer;
  box-shadow: 0px 2px 22px rgba(0,0,0,0.18);
}
.thema-card:hover > .content {
  top: -12.5rem;
}
.thema-card:hover > .link a {
  color: #73cfa4;
}
.thema-image {
  height: 25rem;
  background-size: cover;
  background-position: 50% 45%;
}
