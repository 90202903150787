/*!
---
name: .grid-layout
category: Layout/Grid
tag:
 - component
 - layout
 - grid
 - responsive
 - flexbox
compile: true
---

A grid layout system based on flexbox. It provides sizing variants to size on different percentages.


## Percentage based sizing

The easiest use case is to use simple `.item -w1`, `.item -w1-2`, `.item -w1-3`, `.item -w1-4` for a percentage based side-by-side rendering.

```jade
div.grid-layout
	div.item.-w1
		div.aigis-debug item -w1
	div.item.-w1-2 
		div.aigis-debug item -w1-2
	div.item.-w1-2
		div.aigis-debug item -w1-2
	div.item.-w1-3
		div.aigis-debug item -w1-3
	div.item.-w2-3
		div.aigis-debug item -w2-3
	div.item.-w1-4
		div.aigis-debug item -w1-4
	div.item.-w1-2
		div.aigis-debug item -w1-2
	div.item.-w1-4
		div.aigis-debug item -w1-4
```

## Layouts on different devices

The breakpoints for different screen sizes are specified in the global settings `theme.settings.breakpoints` and can be overwritten in `theme.components.grid-layout.breakpoints`.
The default settings define breakpoints with labels: `.-small-X-X`, `.-medium-X-X`, `.-large-X-X`.

```jade
div.grid-layout
	div.item.-w1.-small-1-3.-medium-1-2.-large-1-4
		div.aigis-debug item -w1 -small-1-3 -medium-1-2 -large-1-4
	div.item.-w1.-small-1-3.-medium-1-2.-large-1-4
		div.aigis-debug item -w1 -small-1-3 -medium-1-2 -large-1-4
	div.item.-w1-2.-small-1-3.-medium-1-2.-large-1-4
		div.aigis-debug item -w1-2 -small-1-3 -medium-1-2 -large-1-4
	div.item.-w1-2.-small-1.-medium-1-2.-large-1-4
		div.aigis-debug item -w1-2 -small-1 -medium-1-2 -large-1-4
```


## Gutter

If you need a spacing between the columns add the `-gutter` variant.
The size of the gutter is specified in the `grid-gutter`variable.

```jade
div.grid-layout.-gutter
	div.item.-w1-4
		div.aigis-debug col -w1-4
	div.item.-w1-4
		div.aigis-debug col -w1-4
	div.item.-w1-2
		div.aigis-debug col -w1-2
```


## Reverse order

To reverse the order in which the columns are displayed, you can add the modifier class `.-reverse` to your table-grid.
The columns will be in DOM order when viewport switches to small layout.

```jade
div.grid-layout.-reverse
	div.item.-w1-4
		div.aigis-debug First column in DOM
	div.item.-w1-4
		div.aigis-debug Second column in DOM
	div.item.-w1-4
		div.aigis-debug Third column in DOM
	div.item.-w1-4
		div.aigis-debug Fourth column in DOM
```

## Vertical centering

You can vertical center with the help of the `flex-layout` component.

```jade
div.grid-layout.flex-layout
	div.item.-w1-4
		div.aigis-debug A column with a lot of content that should wrap on fixed size.
	div.item.-bottom
		div.aigis-debug bottom
	div.item.-center
		div.aigis-debug center
	div.item.-stretch
		div.aigis-debug(style="height:100%") stretch
```

*/



.grid-layout {

	conf = theme.components.grid-layout;
	breakpoints = theme.settings.breakpoints;

	display: flex;
	flex-wrap: wrap;

	&.-gutter {

		margin: ( -( conf.gutter[1] / 2 ) ) ( -( conf.gutter[0] / 2 ) );

		> .item {
			padding: ( conf.gutter[1] / 2 ) ( conf.gutter[0] / 2 );
		}
	}

	&.-reverse {
		flex-direction: row-reverse;
	}

	> .item {

		for name, perc in conf.sizes {
			&.-w{name} {
				flex: 0 0 perc;
				max-width: perc; // ie fix
			}
		}

		/**
		* Generate all media breakpoints based on the breakpoints variable.
		*/
		for breakname, breakwidth in breakpoints {

			@media all and ( min-width: breakwidth ) {

				for name, perc in conf.sizes {
					&.-{breakname}-{name} {
						flex: 0 0 perc;
						max-width: perc; // ie fix
					}
				}
			}
		}
	}
}