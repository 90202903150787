.collapse-ui {

	> .content {

		&.-transition {
			transition: height theme.settings.transition-duration ease;
			overflow: hidden;
			height: 0;
		}
	}

	&.-close {
		> .content {
			display: none;
		}

		.collapse-icon {
			transform: rotate( 90deg );
		}
	}

	.collapse-icon {
		transition: all theme.settings.transition-duration ease;
	}
}