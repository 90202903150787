.icon-taskdimensions {

    width: rem( 38px )
    height: rem( 35px )

    .oekonomie,
    .oekologie,
    .soziales {
        //display:none
        fill: #E6DACD;
    }

    &.-show-oekonomie .oekonomie {
        //display:block
        fill: #8067b6
    }
    &.-show-oekologie .oekologie {
        fill: #75d6f4
    }
    &.-show-soziales .soziales {
        fill: #ed5f7c
    }
}

#taskdixmensions {
    .oekonomie {
        display:none
    }
}