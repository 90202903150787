.input-style {

    &.-basic {
        border: 0
        padding: rem(2px) rem( 15px ) rem( 5px )
    }

    &.-maxwidth {
        width: 100%
    }
}