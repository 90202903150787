.chapter-element {
    height: rem( 80px );

    padding-left: rem( 40px )
    padding-right: rem( 40px )
    cursor: pointer
    
    .state-indicator {
        transition: transform 0.3s
    }

    &.open .state-indicator {
        transform: rotateZ( 180deg )
    }
}