.boolean-slider {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 24px;
}

/* Hide default HTML checkbox */
.boolean-slider input {display:none;}

/* The slider */
.slider-control {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider-control:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider-control {
  //background-color: #2196F3;
}

input:focus + .slider-control {
  //box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider-control:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

/* Rounded sliders */
.slider-control.round {
  border-radius: 34px;
}

.slider-control.round:before {
  border-radius: 50%;
}