.svg-icon {

	fill: currentColor;
	display: block;

	&.-inline {
		display: inline-block;
	}

	for size, value in theme.components.svg-icon {
		
		if( size != 'default' ) {
			&.-{size} {
				{value}	
			}
		} else {
			{value}
		}
	}
}
