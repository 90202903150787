.dialog-trigger {
    position: fixed
    z-index: 99999

    right: rem( 20px )
    bottom: rem( 20px )

    background-color: theme.colors.action
    color: white

    height: rem( 48px )
    border-radius: rem( 24px )

    box-shadow: 0 rem(8px) rem(12px) rgba( 0, 0, 0, 0.3 )

    &:hover {
        background-color: lighten( theme.colors.action, 5 )
        border-color: lighten( theme.colors.action, 5 )

        box-shadow: 0 rem(10px) rem(15px) rgba( 0, 0, 0, 0.3 )
    }

}