.state-dialog {
    position fixed
    z-index: 100100

    top: 0
    bottom: 0
    right: 0

    width: rem( 500px )

    background-color: white;
    
    box-shadow: 0 0 rem(10px) 0 rgba(0,0,0,0.2);


    .dialog-part {
        height: 405px

        padding: rem( 14px ) rem( 40px )

        &.-dark {
            background-color: theme.colors.beige
            height: 100%;
        }
    }

    .dialog-closer {
        position:absolute;
        top: rem( 14px )
        right: rem( 14px )
    }

}