.link-style {
    

    &.-undecorated {
        text-decoration: none;
    }

    &.-active {
        color: theme.colors.action
    }
}