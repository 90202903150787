.material-card {
    width: rem( 215px );
    height: rem( 290px );

    display: inline-block
    overflow:hidden

    font-size: rem( 13px )
    line-height: rem( 18px )
    transition: box-shadow 0.2s;

    img {
        object-fit: cover
        width: 100%
        height: rem( 138px )
    }    

    > .content-container {
        position relative
        transition: all 0.3s
        top:0

        & > .copy {
            position: absolute;
            top: 143px;
            right: 2px;
        }
    }

    &.-hascover:hover > .content-container  {
        top: rem( -138px )
    }

    &:hover {
        cursor pointer
        box-shadow: 0px 2px 22px rgba( 0,0,0,0.18)

        > .content-container {
            //position relative
            //top: rem( -138px )
            //transition: top 0.3s
        }

        .card-meta {
            display:none;
        }
        .card-action {
            display:block
        }
    }

    .card-action {
        display:none
    }

    .card-meta {
    }

    .card-action,
    .card-meta,
    .card-meta a {
        color: theme.colors.text-meta !important
    }

}