.task-checkbox {
    appearance none

    &:before {
        content: ' '
        display:inline-block
        width: rem( 24px )
        height: rem( 24px ) 
        border-radius: rem( 12px )
        background-color:white
        border: rem(2px) solid theme.colors.gray
        font-size: rem( 23px )
        cursor:pointer
    }

    &:checked:before {
        content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24'><path d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z' fill='white'/></svg>");
        color: white 
        background-color: theme.colors.green;
        border-color: theme.colors.green
    }
}