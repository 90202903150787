.filter-term {
    background-color: white

    .term-item {
        overflow:hidden

        input {
            max-width: 100%
            border:0
        }
    }
}