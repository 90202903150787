.material-sidebar {
    position: absolute !important
    background-color: theme.colors.beige

    width: rem( 240px )
    top: rem( 1px ) !important

    z-index: 10 !important

    overflow-x: hidden
    overflow-y: scroll
    margin-bottom: rem( -30px )
}