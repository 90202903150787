/*!
---
name: Global settings
category: Shared/Variables
template: variables
tag:
 - variables
variables:
 - name: "text-line-height"
   description: "The line height of the body"
   default: "1.5rem"
 - name: "font-family"
   description: "The font-family of the body"
   default: "'Helvetica', sans-serif"
 - name: "text-rendering"
   description: "The text-rendering of the body"
   default: "optimizeLegibility"
 - name: "transition-duration"
   description: "The duration of the slide transitions"
   default: "0.4s"
 - name: "border-radius"
   description: "The default radius for borders"
   default: "2px"
---

These variables setups some global text formatting and styling.
*/

theme.settings = {
  text-line-height: rem( 24px ),
  text-font: 'Roboto'\, serif,
  heading-font: 'Roboto'\, serif,
  text-rendering: optimizeLegibility,
  transition-duration: 0.3s,
  border-radius: 2px,
  breakpoints: {
    small: rem( 768px ),
    medium: rem( 992px ),
    large: rem( 1200px )
  },
}

