/*!
---
name: .padding-box
category: Box/Padding
tag:
 - component
 - layout
compile: true
---

A simple component to add paddings

## Applying paddings

The easiest use case is to use simple `.padding-box .-all` for a padding on top, right, bottom and left with default size or
`.-top`, `.-right`, `.-bottom` and `.-left` for specified sides

```jade
div.aigis-debug.padding-box.-all Defaut padding applied on top, right, bottom and left
```
```jade
div.aigis-debug.padding-box.-bottom Defaut padding applied on bottom
```

## Variants

The variants for the paddings are `.-top-{size}`, `.-right-{size}`, `.-bottom-{size}` and `.left-{size}` together with sizes small, medium or large.
All sizes are defined in the variable theme.components.padding-box. Values are `small`, `medium`, and `large`

```jade
div.aigis-debug.padding-box.-left-small Small padding applied on left
```
```jade
div.aigis-debug.padding-box.-right-small(style="text-align:right") Small padding applied on right
```
```jade
div.aigis-debug.padding-box.-top-small Small padding applied on top
```
```jade
div.aigis-debug.padding-box.-bottom-small Small padding applied on bottom
```

There are also shortcuts for vertical `.-v-{size}` and horizontal `.-h-{size}` together with sizes

```jade
div.aigis-debug.padding-box.-v-medium Medium padding applied on top and bottom
```
```jade
div.aigis-debug.padding-box.-h-medium(style="width: 300px") Medium padding applied on right and left
```
```jade
div.aigis-debug.padding-box.-h-medium.-top-large(style="width: 400px") Medium padding applied on right and left, large padding applied on top
```
*/

.padding-box{

    conf = theme.components.padding-box;

    for size, value in conf {

        for side in top right bottom left{
            
            if size != 'default' {
                &.-{side}-{size}{
                    padding-{side}: value;    
                }
            } else {
                &.-{side}{
                    padding-{side}: value;
                }
            }      
        }

        if size != 'default' {
            &.-h-{size}{
                padding-left: value;
                padding-right: value;    
            }

            &.-v-{size}{
                padding-top: value;
                padding-bottom: value;    
            }

            &.-all-{size}{
                padding: value value;    
            }
        } else {
            &.-h{
                padding-left: value;
                padding-right: value;    
            }

            &.-v{
                padding-top: value;
                padding-bottom: value;    
            }
            &.-all{
                padding: value value;    
            }
        }
    }

    &.-gutter {
        gutter = theme.components.grid-layout.gutter;
        padding: ( gutter[0] / 2 ) ( gutter[0] / 2 );    
    }
}