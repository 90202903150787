/*!
---
name: .shadow-box
category: Box/Shadow
tag:
 - component
 - shadow
 - helper
compile: true
---

A box to control box-shadows with different variants

## Default variants
```jade
div.shadow-box Demo content
div.shadow-box.-inset Demo content
div.shadow-box.-zero Use zero shadow in animations
```
*/
.shadow-box {
	
	conf = theme.components.shadow-box;

	for name, value in conf {
		if name != 'default' {
            &.-{name} {
                {value}
            }
        } else {
			{value}
		}
	}
}