/*!
---
name: .margin-box
category: Box/Margin
tag:
 - component
 - layout
compile: true
---

A component to add margins

## Applying margins

The easiest use case is to use simple `.margin-box .-all` for a margin on top, right, bottom and left with default size or
`.-top`, `.-right`, `.-bottom` and `.-left` for specified sides.

```jade
div.margin-box.-all
	div.aigis-debug Default margin applied on top, right, bottom and left
```
```jade
div.margin-box.-bottom
	div.aigis-debug Default margin applied on bottom
```


## Variants

The variants for the margins are `.-top-{size}`, `.-right-{size}`, `.-bottom-{size}` and `.left-{size}` together with sizes small, medium or large.
All sizes are defined in the variable theme.components.margin-box. Values are `small`, `medium`, `large` and `auto`

```jade
div.margin-box.-left-small
    div.aigis-debug Small margin applied on left
```
```jade
div.margin-box.-right-small
    div.aigis-debug Small margin applied on right
```
```jade
div.margin-box.-top-small
    div.aigis-debug Small margin applied on top
```
```jade
div.margin-box.-bottom-small
    div.aigis-debug Small margin applied on bottom
```

There are also shortcuts for vertical `.-v-{size}` and horizontal `.-h-{size}` together with sizes small, medium and large

```jade
div.margin-box.-v-medium
    div.aigis-debug Medium margin applied on top and bottom
```
```jade
div.margin-box.-h-medium
    div.aigis-debug Medium margin applied on right and left
```

## Variants for auto margins

```jade
div.margin-box.-auto.-h(style="width: 200px")
    div.aigis-debug Auto margin applied on right and left
```
```jade
div.margin-box.-auto.-v(style="height: 46px")
    div.aigis-debug Auto margin applied on top and bottom
```

*/

.margin-box {

    conf = theme.components.margin-box;

    for size, value in conf {

        for side in top right bottom left{
            
            if size != 'default' {
                &.-{side}-{size}{
                    margin-{side}: value;    
                }
            } else {
                &.-{side}{
                    margin-{side}: value;
                }
            }      
        }

        if size != 'default' {
            &.-h-{size}{
                margin-left: value;
                margin-right: value;    
            }

            &.-v-{size}{
                margin-top: value;
                margin-bottom: value;    
            }

            &.-all-{size}{
                margin: value value;    
            }
        } else {
            &.-h{
                margin-left: value;
                margin-right: value;    
            }

            &.-v{
                margin-top: value;
                margin-bottom: value;    
            }
            &.-all{
                margin: value value;    
            }
        }
    }

    &.-auto{

        &.-v{
            margin-top: auto;    
            margin-bottom: auto;    
        }

        &.-h{
            margin-left: auto;    
            margin-right: auto;    
        }    
    }

}