/*!
---
name: .drop-down-menu
category: DropDown
tag:
 - component
 - dropdown
compile: true
---

This styles a menu inside a .drop-down.

```jade
div.drop-down-menu
	div.item Menu 1
	div.item Menu 2
	div.item Menu 3
```

Used in combination with the .drop-down itself
```jade
div.drop-down.-open
	div.toggle.aigis-debug Toggle
	div.content.drop-down-menu
		div.item Menu 1
		div.item Menu 2
		div.item Menu 3
```

*/
.drop-down-menu {

	box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.17);
	border: 1px solid theme.colors.line;
	border-top: none;

	> .item {
		& + .item {
			border-top: 1px solid theme.colors.line;
		}
	}
}