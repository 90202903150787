.hotspot-point {
	height: 20px;
	width: 20px;
	border-radius: 12px;
	background-color: rgba(255,255,255,0.66);
	margin-left: -10px;
	margin-top: -10px;
}

.hotspot-rect {
	background-color: rgba( 0, 0, 0, 0.2 );
	border: 1px solid theme.colors.text-inverted;
}
