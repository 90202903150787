/*!
---
name: .image-responsive
category: Responsive/Image
tag:
 - responsive
compile: true
---

This class makes the image scaling to its max width.

```jade
img.image-responsive(src='http://www.byui.edu/images/agriculture-life-sciences/flower.jpg')
```

## Variants

`.-full` forces the picture to span to 100% of the parent

```jade
img.image-responsive.-full(src='http://www.byui.edu/images/agriculture-life-sciences/flower.jpg')
```
*/

.image-responsive {
	max-width: 100%;
	height: auto;
	display: block;

	&.-full{
		width: 100%;
	}

	&.-inset {
		max-height: 100%;	
	}
}
