/*!
---
name: .fixed-page-layout
category: Layout/Fixed Page
tag:
 - component
 - page
compile: true
---

A page layout with a fixed header and optional sidebars.
With the `-margin-{top|left|right}`, `-margin-{top|left|right}-small` variants you can control the spacing.
If the content for example needs a spacing towards the header use: `.content.-margin-top`.
If the sidebar show be below the header use: `.sidebar.-left.-margin-top`.


There are variants to resize the header and sidebars to a smaller width/height: `.header.-small`, `.sidebar.-small`.
If you need a spacing on your content or sidebar you need to adjust that manually like this:
```
div.fixed-page-layout
	div.header.-small
		div.aigis-debug(style="width:100%;height:100%")  Header
	div.content.-margin-top-small Content
	div.sidebar.-left.-margin-top-small.-small
		div.aigis-debug(style="height:100%")  Left Sidebar
```

## Header with sidebars

```jade
div.fixed-page-layout
	div.header
		div.aigis-debug(style="width:100%;height:100%")  Header
	div.content.-margin-top Content
	div.sidebar.-left.-margin-top
		div.aigis-debug(style="height:100%")  Left Sidebar
	div.sidebar.-right
		div.aigis-debug(style="height:100%")  Right Sidebar
```
*/
.fixed-page-layout {

	conf = theme.components.fixed-page-layout;


	> .header {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		height: conf.header.default;
		transition: all 0.2s;
		z-index: 1000;

		&.-small {
			height: conf.header.small;
		}

		&.-hide {
			transform: translateY( -100% );
		}
	}

	> .sidebar {
		position: fixed;
		top: 0;
		bottom: 0;
		width: conf.sidebar.default;
		transition: all 0.2s;
		z-index: 1005;

		&.-margin-top {
			top: conf.header.default;
		}

		&.-margin-top-small {
			top: conf.header.small;
		}

		&.-small {
			width: conf.sidebar.small;
		}

		&.-left {
			left: 0;

			&.-hide {
				transform: translateX( -100% );
			}
		}

		&.-right {
			right: 0;

			&.-hide {
				transform: translateX( 100% );
			}
		}

	}

	> .content {

		&.-margin-left {
			margin-left: conf.sidebar.default;
		}

		&.-margin-left-small {
			margin-left: conf.sidebar.small;
		}

		&.-margin-right {
			margin-right: conf.sidebar.default;
		}

		&.-margin-right-small {
			margin-right: conf.sidebar.small;
		}

		&.-margin-top {
			margin-top: conf.header.default;
		}

		&.-margin-top-small {
			margin-top: conf.header.small;
		}

		transition: margin 0.2s;
	}
}