/*!
---
name: .button-style
category: Components/Button
tag:
 - component
 - button
compile: true
---

A button component as a base for different button variants defined in its configuration.

```jade
button.button-style.-main Action
```
*/
.button-style {

	appearance: none;
	background: transparent;
	border: none;
	display: inline-block;
	cursor: pointer;
	outline: none;
	white-space: nowrap;

	display: flex;
	align-items: center;

	for name, value in theme.components.button-style {
	
		&.-{name} {
			{value}
		}
	}
}