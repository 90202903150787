/*!
---
name: .max-width-layout
category: Layout/Page
tag:
 - component
 - layout
 - responsive
compile: true
---

This component will limit its content to a maximum width. Forthermore it can center its content.
Usefull for page content that should not span the full width.


## Percentage based sizing

*/



.max-width-layout {

	conf = theme.components.max-width-layout;
	breakpoints = theme.settings.breakpoints;

	/**
	* Generate all media breakpoints based on the breakpoints variable.
	*/
	for breakname, maxwidth in conf {

		// check if corresponding breakpoint exists
		breakwidth = breakpoints[ breakname ]
		if( breakwidth ) {

			@media all and ( min-width: breakwidth ) {

				max-width: maxwidth;
			}
		}
	}

	&.-center {
		margin-left: auto;
		margin-right: auto;	
	}
}