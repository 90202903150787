/*!
---
name: .overflow-text
category: Text
tag:
 - component
 - text
compile: true
---

This component cuts the text on a given height and adds a fadeout effect.
This is usefull for text containers that have more content than space.


```jade
div.overflow-text(style="width: 200px;height: 70px") This is a very long text that is rwaped and cut at the end.
```
*/

.overflow-text {

	conf = theme.components.overflow-text;

	overflow: hidden;
    position: relative;

	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		height: conf.height;
		background: conf.gradient;
    }
}


