.hidden {
    display:none;
}

.v-stretch {
    height: 100%;
}
.h-stretch {
    width: 100%;
}

.icon-down {
    position relative
    top: rem( 2px )
}