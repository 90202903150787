/*!
---
name: .control-responsive
category: Responsive/Control
tag:
 - responsive
 - display
compile: true
---

Controls the display of an element depending on the current breakpoint

## Hide

`.-hide-medium` hides an element as long as the screen is wider than the breakpoint `medium`.

```jade
div.control-responsive.-hide-medium Only shown when the screen is wider than the breakpoint medium
```

## Show

`.-show-medium` show an element as long as the screen is narrower than the breakpoint `medium`.

```jade
div.control-responsive.-show-medium Only shown when the screen is narrower than the breakpoint `medium`
```

*/


.control-responsive {

	breakpoints = theme.settings.breakpoints;

	for breakname, breakwidth in breakpoints {
		&.-hide-{ breakname } {
			@media all and ( max-width: breakwidth ) {
				display: none;
			}
		}
		&.-show-{ breakname } {
			@media all and ( min-width: breakwidth ) {
				display: none;
			}
		}
	}
}