/*!
---
name: .media-responsive
category: Responsive/Media
tag:
 - responsive
compile: true
---

`.media-responsive` - This makes a the `.media-responsive` div a container for any media element (iframe, video, ... ) that responsive scales with a fix ratio.
The media element ( child ) needs the class `.media` to fully fill out the parents size.

The `.media-responsive` component has to predefined modifiers: `.-media-4-3`, `.-media-16-9` that define a fix ratio.

```jade
div.media-responsive.-media-16-9
	iframe.media(src="https://www.youtube.com/embed/TCd6PfxOy0Y")
```

```jade
div.media-responsive.-media-4-3
	iframe.media(src="https://www.youtube.com/embed/TCd6PfxOy0Y")
```

*/
.media-responsive {

	conf = theme.components.media-responsive;

	width: 100%;
	position: relative;
	padding-bottom: values( conf.ratios )[0];

	for name, value in conf.ratios {
		&.-media-{name} {
			padding-bottom: value;
		}
	}

	> .media {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}