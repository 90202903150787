a {
    color: theme.colors.text
    cursor: pointer

    border-bottom: rem( 2px ) solid theme.colors.action

    &.-undecorated {
        border-bottom: 0
    }

    &:hover {
        color: theme.colors.action
    }
}