.hide-responsive {

	breakpoints = theme.settings.breakpoints;

	for breakname, breakwidth in breakpoints {
		&.-{ breakname } {
			@media all and ( max-width: breakwidth ) {
				display: none;
			}
		}
	}
}