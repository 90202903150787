/*!
---
name: .border-box
category: Box/Border
tag:
 - component
 - border
compile: true
---

A box to control borders on all four sides.

```jade
div.border-box.-left Some demo content
```

```jade
div.border-box.-all Some demo content
```
*/
.border-box {

	conf = theme.components.border-box;
	
	for variant, value in conf {

        for side in top right bottom left {
            
            if variant != 'default' {
                &.-{side}-{variant} {
					border-{side}-style: solid;
                    {value}
                }
            } else {
                &.-{side} {
					border-{side}-style: solid;
                    {value}
                }
            }      
        }

		if variant != 'default' {
			&.-all-{variant} {
				border-style: solid;
				{value}
			}
		} else {
			&.-all {
				border-style: solid;
				{value}
			}
		}
	}

	&.-round {
		border-radius: 50%;	
	}
}