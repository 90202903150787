.header-menu {
    min-width: rem( 270px )
    position fixed

    top: rem( 55px )
    left: rem( 60px )

    padding: rem( 20px ) rem( 40px )

    background-color: theme.colors.beige

    border: rem( 0.5px ) solid rgba(0,0,0,0.25)
    box-shadow: 0 rem(3px) rem(24px) rgba( 0,0,0,0.2)
}