.page-footer {
    background-color: theme.colors.green
    color: theme.colors.white

    position: relative
    z-index:10000

    height: rem( 70px )

    a {
        color: theme.colors.white

        &:hover {
            color: theme.colors.white
        }
    }

    & img {
        height: rem( 48px );
    }
}