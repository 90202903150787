.header-icon {
    width: rem( 70px );
    border-right:1px solid theme.colors.line;
    display:inline-block
    color: theme.colors.text

    &:hover {
        color: theme.colors.action
        cursor: pointer
    }
}