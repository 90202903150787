.button-style {
    height: rem( 45px );
    min-width: rem( 120px );

    display: inline-block;

    border: 2px solid theme.colors.action

    &:hover {
        background-color: theme.colors.action
        color: white;
    }
}