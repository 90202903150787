.thema-card {
    // width: rem( 312px );
    height: rem( 400px );

    position: relative;

    // display: inline-block

    transition: box-shadow 0.2s

    & > .content-wrapper {


        & > .content {
            overflow-y: scroll; 
            -ms-overflow-style: none;
            position: absolute;
            height: rem( 354px );
            top: 0px;
            transition: top 0.3s; 

        }
    
    }


    > .link {
        height: rem( 55px );
        width: 100%;
        position: absolute;
        // bottom: -55px;
        bottom: 0px;
        //transition: bottom 0.3s;
    }


    &:hover {
        cursor pointer
        box-shadow: 0px 2px 22px rgba( 0,0,0,0.18)

        > .content {
            top: rem( -200px );
        }
        > .link {
            // bottom: rem( 0px );

            a {
                color: theme.colors.action;
            }

        }
    }



}