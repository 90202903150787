.swap-image {
    cursor:pointer
    display:inline-block

    &.-active-mini {
        .-passive {
            display:none;
        }
        .-active{
            display:inline;
        }
    }
    .-passive {
        display:inline;
    }
    .-active {
        display:none;
    }
}